
<template>
  <div class="max-w-lg m-auto">
     <!-- colors start -->
<section>
   <div class="container">
      <div class="row">
         <div class="col-md-12">
            <h1 class="heading_3 mb_20 mt_20 font_bold">Colors</h1>
         </div>
         <div class="col-md-2">
            <div class="text-center">
               <div class="text_primary bg_primary py_24 px_24">
               </div>
               <p>bg_primary</p>
            </div>
         </div>
         <div class="col-md-2">
            <div class="text-center">
               <div class="text_primary bg_secondary py_24 px_24">
               </div>
               <p>bg_secondary</p>
            </div>
         </div>
         <div class="col-md-2">
            <div class="text-center">
               <div class="text_orange bg_orange py_24 px_24">
               </div>
               <p>bg_orange</p>
            </div>
         </div>
         <div class="col-md-2">
            <div class="text-center">
               <div class="text_silverchalice bg_silverchalice py_24 px_24">
               </div>
               <p>bg_silverchalice</p>
            </div>
         </div>
         <div class="col-md-2">
            <div class="text-center">
               <div class="text_black bg_black py_24 px_24">
               </div>
               <p>bg_black</p>
            </div>
         </div>
         <div class="col-md-2">
            <div class="text-center">
               <div class="text_balticsea bg_balticsea py_24 px_24">
               </div>
               <p>bg_balticsea</p>
            </div>
         </div>
         <div class="col-md-2">
            <div class="text-center">
               <div class="text_concord bg_concord py_24 px_24">
               </div>
               <p>bg_concord</p>
            </div>
         </div>
         <div class="col-md-2">
            <div class="text-center">
               <div class="text_dovegray bg_dovegray py_24 px_24">
               </div>
               <p>bg_dovegray</p>
            </div>
         </div>
         <div class="col-md-2">
            <div class="text-center">
               <div class="text_woodsmoke bg_woodsmoke py_24 px_24">
               </div>
               <p>bg_woodsmoke</p>
            </div>
         </div>
         <div class="col-md-2">
            <div class="text-center">
               <div class="text_lightorange bg_lightorange py_24 px_24">
               </div>
               <p>bg_lightorange</p>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-md-12">
            <h1 class="heading_3 mb_20 mt_20 font_bold">Border Colors</h1>
         </div>
         <div class="col-md-2">
            <div class="text-center">
               <div class="border_primary custome_border py_24 px_24">
               </div>
               <p>border_primary</p>
            </div>
         </div>
         <div class="col-md-2">
            <div class="text-center">
               <div class="border_secondary custome_border py_24 px_24">
               </div>
               <p>border_secondary</p>
            </div>
         </div>
         <div class="col-md-2">
            <div class="text-center">
               <div class="border_orange custome_border py_24 px_24">
               </div>
               <p>border_orange</p>
            </div>
         </div>
         <div class="col-md-2">
            <div class="text-center">
               <div class="border_silverchalice custome_border py_24 px_24">
               </div>
               <p>border_silverchalice</p>
            </div>
         </div>
         <div class="col-md-2">
            <div class="text-center">
               <div class="border_black custome_border py_24 px_24">
               </div>
               <p>border_black</p>
            </div>
         </div>
         <div class="col-md-2">
            <div class="text-center">
               <div class="border_balticsea custome_border py_24 px_24">
               </div>
               <p>border_balticsea</p>
            </div>
         </div>
         <div class="col-md-2">
            <div class="text-center">
               <div class="border_concord custome_border py_24 px_24">
               </div>
               <p>border_concord</p>
            </div>
         </div>
         <div class="col-md-2">
            <div class="text-center">
               <div class="border_dovegray custome_border py_24 px_24">
               </div>
               <p>border_dovegray</p>
            </div>
         </div>
         <div class="col-md-2">
            <div class="text-center">
               <div class="border_woodsmoke custome_border py_24 px_24">
               </div>
               <p>border_woodsmoke</p>
            </div>
         </div>
         <div class="col-md-2">
            <div class="text-center">
               <div class="border_lightorange custome_border py_24 px_24">
               </div>
               <p>border_lightorange</p>
            </div>
         </div>
      </div>
   </div>
</section>
<!-- colors end -->
<!-- Typography start -->
<section class="mt_40">
   <div class="container ">
      <div class="row mb_60">
         <div class="col-md-12">
            <h1 class="heading_3 mb_20 mt_20 font_bold">Typography</h1>
         </div>
         <div class="col-md-12 mb_60">
            <h1 class="heading_1 font_bold mb_20 text_balticsea">H1 Keyboard & Mouse</h1>
            <h2 class="heading_2 font_bold mb_20">H2 Working from home <span class="text_primary">guide</span></h2>
            <h3 class="heading_3 font_bold mb_20">H3 BE MINDFUL BE PRESENT BE GREAT</h3>
            <h4 class="heading_4 font_bold mb_20">H4 The power of movement</h4>
            <h5 class="heading_5 font_bold mb_20">H5 Workout Calendar</h5>
            <h6 class="sub_heading_1 font_bold">sub_heading_1</h6>
         </div>
         <div class="col-md-6">
            <p class="text_primary font_size_22">text_primary</p>
            <p class="text_secondary font_size_22">text_secondary</p>
            <p class="text_orange font_size_22">text_orange</p>
            <p class="text_silverchalice font_size_22">text_silverchalice</p>
            <p class="text_black font_size_22">text_black</p>
            <p class="text_balticsea font_size_22">text_balticsea</p>
            <p class="text_concord font_size_22">text_concord</p>
            <p class="text_dovegray font_size_22">text_dovegray</p>
            <p class="text_woodsmoke font_size_22">text_woodsmoke</p>
            <p class="text_lightorange font_size_22">text_lightorange</p>
         </div>
         <div class="col-md-6 mt_60">
            <p class="font_size_12">font_size_12</p>
            <p class="font_size_14">font_size_14</p>
            <p class="font_size_16">font_size_16</p>
            <p class="font_size_18">font_size_18</p>
            <p class="font_size_20">font_size_20</p>
            <p class="font_size_22">font_size_22</p>
            <p class="font_size_24">font_size_24</p>
            <p class="font_size_26">font_size_26</p>
            <p class="font_size_28">font_size_28</p>
            <p class="font_size_30">font_size_30</p>
            <p class="font_size_32">font_size_32</p>
            <p class="font_size_34">font_size_34</p>
         </div>
      </div>
   </div>
</section>
<!-- Typography start -->
<!-- Buttons start -->
<section class="bg_silverchalice py_24">
   <div class="container">
      <div class="row">
         <div class="col-md-12">
            <h6 class="font_size_24 mb_10 font_bold">Buttons</h6>
            <p class="text_balticsea">A button indicates a possible user action</p>
         </div>
         <div class="col-md-4">
            <a href="#" class="btn btn_primary">Take An Assessment</a>
         </div>
         <div class="col-md-4">
            <a href="#" class="btn btn_secondary">Take An Assessment</a>
         </div>
         <div class="col-md-4">
            <a href="#" class="btn btn_outline">
               <svg xmlns="http://www.w3.org/2000/svg" id="play_icon" width="31" height="31" viewBox="0 0 31 31">
                  <circle id="bg" cx="15.5" cy="15.5" r="15.5" fill="#ff9500"/>
                  <g id="Triangle" transform="translate(21.478 9.205) rotate(90)" fill="#fff" stroke-linejoin="round" stroke-miterlimit="10">
                     <path d="M 13.20730304718018 9.704782485961914 L 12.27304363250732 9.704782485961914 L 3.246765118092299e-06 9.704782485961914 L -0.9342567324638367 9.704782485961914 L -0.4160267412662506 8.927433013916016 L 5.720493316650391 -0.2773473262786865 L 6.136523246765137 -0.9013873338699341 L 6.552553176879883 -0.2773473262786865 L 12.68907356262207 8.927433013916016 L 13.20730304718018 9.704782485961914 Z" stroke="none"/>
                     <path d="M 6.136523246765137 2.86102294921875e-06 L 2.86102294921875e-06 9.204782485961914 L 12.27304363250732 9.204782485961914 L 6.136523246765137 2.86102294921875e-06 M 6.136523246765137 -0.9999971389770508 C 6.470873355865479 -0.9999971389770508 6.783103466033936 -0.8328971862792969 6.968573093414307 -0.5546970367431641 L 13.10509300231934 8.650082588195801 C 13.30966377258301 8.956942558288574 13.32873344421387 9.351482391357422 13.15471363067627 9.676642417907715 C 12.98070335388184 10.00180244445801 12.64184284210205 10.20478248596191 12.27304363250732 10.20478248596191 L 2.86102294921875e-06 10.20478248596191 C -0.3687963485717773 10.20478248596191 -0.7076568603515625 10.00180244445801 -0.8816671371459961 9.676642417907715 C -1.055686950683594 9.351482391357422 -1.036616325378418 8.956942558288574 -0.8320465087890625 8.650082588195801 L 5.304473400115967 -0.5546970367431641 C 5.489943027496338 -0.8328971862792969 5.802173137664795 -0.9999971389770508 6.136523246765137 -0.9999971389770508 Z" stroke="none" fill="#fff"/>
                  </g>
               </svg>
               Take An Assessment
            </a>
         </div>
      </div>
   </div>
</section>
<!-- Buttons end -->
<!-- form start -->
<section class="mt_60 mb_60">
   <div class="container">
      <div class="row">
         <div class="col-md-12 mb_20">
            <h1 class="heading_3 mb_20 mt_20 font_bold">Form</h1>
            <h6 class="font_size_24 mb_10 font_bold">Input Fields</h6>
            <p class="text_balticsea">A field is a form element containing a label and an input</p>
         </div>
         <div class="col-md-5">
            <form action="">
               <div class="form-group">
                  <label class="font_size_24 mb_20 font_bold">User Input</label>
                  <input type="text" placeholder="Please enter your work email address " class="mb_16 form-control">
               </div>
               <div class="form-group">
                  <label class="font_size_24 mb_20 font_bold">User Input</label>
                  <input type="text" placeholder="Please enter your work email address " class="mb_16 form-control border_green">
               </div>
               <div class="form-group">
                  <label class="font_size_24 mb_20 font_bold">User Input</label>
                  <input type="text" placeholder="Please enter your work email address " class="mb_16 form-control border_red">
               </div>
            </form>
            <!-- <form>
               <input type="text" placeholder="Please enter your work email address " class="mb_16 custome_border font_size_24   text_balticsea">
               <input type="text" placeholder="Please enter your work email address " class="mb_16 custome_border font_size_24   text_balticsea">
               </form> -->
         </div>
         <div class="col-md-12 mt_40 mb_20">
            <h6 class="font_size_24 mb_10 font_bold">Radio Button</h6>
            <p class="text_balticsea">A form can include radio checkboxes</p>
                <div class="custom_radio_main_box">
                            <label class="font_size_24 mb_20 font_bold">Are you experiencing discomfort?</label>
                            <div class="custom_radio_btn_box d-flex">
                                <div class="custom_radio_btn mr_16 form-group">
                                    <input type="radio" name="yes-no" class="form-control">
                                    <span class="text_balticsea border_dovegray custome_border">Yes</span>
                                </div>
                               <div class="custom_radio_btn form-group">
                                <input type="radio" name="yes-no" class="form-control">
                                <span class="text_balticsea border_dovegray custome_border">No</span>
                                </div>
                            </div>
                            </div>
         </div>
      </div>
   </div>
</section>

<!-- form end -->
<!-- Accordion  start -->
<section class="accordion_sec mb_60">
   <div class="container">
         <div class="row justify-content-center">
          <div class="col-md-12 col-xl-8">
            <h1 class="heading_3 mb_20 mt_20 font_bold">Accordion</h1>
            <h6 class="font_size_24 mb_10 font_bold">Dropdown</h6>
            <p class="text_balticsea mb_0">A form can include Accordion</p>
         </div>
      </div>
      <div class="row justify-content-center">
          <div class="col-md-12 col-xl-8">
            <div class="accordion" id="accordionExample">
               <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                     <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                     <img src="../assets/images/accordion_icon.png" alt="img"> Is your posture in line with your activity? 
                     </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                     <div class="accordion-body">
                        <p >
                           <strong>Reclined posture </strong>
                           is perfect for passive activities like reading, watching videos, or relaxing.
                        </p>
                        <p>
                           <strong>Forward and supported</strong>
                           posture is great for activities like writing, drawing, creating to-do lists, and even doodling.
                        </p>
                        <p>
                           <strong>Upright balanced posture </strong>
                           is best for working with a computer, laptop or doing desktop work
                        </p>
                     </div>
                  </div>
               </div>
               <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                     <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                     <img src="../assets/images/accordion_icon.png" alt="img"> Accordion Item #2
                     </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                     <div class="accordion-body">
                        <p >
                           <strong>Reclined posture </strong>
                           is perfect for passive activities like reading, watching videos, or relaxing.
                        </p>
                        <p>
                           <strong>Forward and supported</strong>
                           posture is great for activities like writing, drawing, creating to-do lists, and even doodling.
                        </p>
                        <p>
                           <strong>Upright balanced posture </strong>
                           is best for working with a computer, laptop or doing desktop work
                        </p>
                     </div>
                  </div>
               </div>
               <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                     <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                     <img src="../assets/images/accordion_icon.png" alt="img"> Accordion Item #3
                     </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                     <div class="accordion-body">
                        <p >
                           <strong>Reclined posture </strong>
                           is perfect for passive activities like reading, watching videos, or relaxing.
                        </p>
                        <p>
                           <strong>Forward and supported</strong>
                           posture is great for activities like writing, drawing, creating to-do lists, and even doodling.
                        </p>
                        <p>
                           <strong>Upright balanced posture </strong>
                           is best for working with a computer, laptop or doing desktop work
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<!-- Accordion  end -->
<!-- card  start-->
<section>
   <div class="container">
      <div class="row">
         <div class="col-md-12 mb_20">
            <h1 class="heading_3 mb_20 mt_20 font_bold">Cards</h1>
            <h6 class="font_size_24 mb_10 font_bold">Card</h6>
            <p class="text_balticsea">Different type of card</p>
         </div>
         <div class="row">
            <!-- card 1 start -->
            <div class="col-md-3">
               <div class="custome_card card1">
                  <span></span>
                  <h5 class="font_bold font_size_22 mb_22 text_balticsea">Working from home?</h5>
                  <p class="text_balticsea font_size_18 mb_0 "> 
                     Discover the best ways to bring ergo into your home, boost your productivity and feel great!
                  </p>
               </div>
            </div>
            <!-- card 1 end -->
            <!-- Video card start -->
            <div class="col-md-4 mb_60">
               <div class="video_card">
                  <div class="video_img">
                     <!-- <div class="position-absolute video_text_box">
                        <h5 class="text-uppercase font_size_24 text_white">Stress buster series</h5>
                        </div> -->
                     <img src="./../assets/images/neck_img.png" alt="img" class="custome_border border_black">
                  </div>
                  <a class="font_bold font_size_16 text_balticsea mt_18"><svg xmlns="http://www.w3.org/2000/svg" id="play_icon" width="31" height="31" viewBox="0 0 31 31">
  <circle id="bg" cx="15.5" cy="15.5" r="15.5" />
  <g id="Triangle" transform="translate(21.478 9.205) rotate(90)" fill="#fff" stroke-linejoin="round" stroke-miterlimit="10">
    <path d="M 13.20730304718018 9.704782485961914 L 12.27304363250732 9.704782485961914 L 3.246765118092299e-06 9.704782485961914 L -0.9342567324638367 9.704782485961914 L -0.4160267412662506 8.927433013916016 L 5.720493316650391 -0.2773473262786865 L 6.136523246765137 -0.9013873338699341 L 6.552553176879883 -0.2773473262786865 L 12.68907356262207 8.927433013916016 L 13.20730304718018 9.704782485961914 Z" stroke="none"/>
    <path d="M 6.136523246765137 2.86102294921875e-06 L 2.86102294921875e-06 9.204782485961914 L 12.27304363250732 9.204782485961914 L 6.136523246765137 2.86102294921875e-06 M 6.136523246765137 -0.9999971389770508 C 6.470873355865479 -0.9999971389770508 6.783103466033936 -0.8328971862792969 6.968573093414307 -0.5546970367431641 L 13.10509300231934 8.650082588195801 C 13.30966377258301 8.956942558288574 13.32873344421387 9.351482391357422 13.15471363067627 9.676642417907715 C 12.98070335388184 10.00180244445801 12.64184284210205 10.20478248596191 12.27304363250732 10.20478248596191 L 2.86102294921875e-06 10.20478248596191 C -0.3687963485717773 10.20478248596191 -0.7076568603515625 10.00180244445801 -0.8816671371459961 9.676642417907715 C -1.055686950683594 9.351482391357422 -1.036616325378418 8.956942558288574 -0.8320465087890625 8.650082588195801 L 5.304473400115967 -0.5546970367431641 C 5.489943027496338 -0.8328971862792969 5.802173137664795 -0.9999971389770508 6.136523246765137 -0.9999971389770508 Z" stroke="none" fill="#fff"/>
  </g>
</svg> Neck</a>
               </div>
            </div>
            <!-- Video card end -->
            <!-- image text card start -->
            <div class="col-md-4 mb_60">
               <div class="image_text_card">
                  <div class="position-absolute image_text_box">
                     <h5 class="font_size_34 font_bold text_black">Everyday Stretches</h5>
                  </div>
                  <img src="./../assets/images/yoga.png" alt="img" class="custome_border border_black">
               </div>
            </div>
            <!-- image text card start -->
         </div>
         <div class="row mb_50">
            <!-- card 2 start -->
            <div class="col-md-3">
               <div class="custome_card card2">
                  <img src="../assets/images/couches.png" alt="img" class="mb_66">
                  <div class="card2_text_box">
                     <h5 class="font_bold text-center font_size_26 mb_20 text_balticsea">Couches</h5>
                     <p class="text-center font_size_20 mb_20 text_balticsea">
                        Don’t be a couch slouch!  We’ll show you how. 
                     </p>
                     <p class="text_cconcord font_size_14 text_silverchalice">
                        5 minute journey
                     </p>
                  </div>
               </div>
            </div>
            <!-- blank -->
            <div class="col-md-1"></div>
            <!-- blank -->
            <div class="col-md-3">
               <div class="custome_card card2">
                  <img src="../assets/images/posture.png" alt="img" class="mb_34">
                  <span class="d-block text_primary mb_6 font_size_16">Ergo Essentials</span>
                  <h5 class="font_bold font_size_30 mb_10 text_balticsea">Posture Perfect</h5>
                  <p class="font_size_18 mb_24 text_balticsea">
                     No matter where you work take your perfect posture with you. 
                  </p>
                  <p class="text_cconcord font_size_14 text_silverchalice">
                     10 minute journey
                  </p>
               </div>
            </div>
            <!-- card 2 end -->
         </div>
         <!-- border icon card start -->
         <div class="row mb_60">
            <div class="col-md-3">
               <div class="icon_border_card custome_border border_black">
                  <div class="mb_24">
                     <img src="../assets/images/alarm.svg" alt="img" class="box_icon">
                  </div>
                  <h4 class="font_size_30 text_balticsea mb_14 font_bold">
                     Aim for 7 - 8 hours
                  </h4>
                  <p class="font_size_22 text_balticsea mb_0">
                     Sounds crazy, but make sure you schedule enough time for sleep. Instead of cutting back on sleep in order to tackle
                     the rest of your daily tasks, put sleep at the top of your to-do list and aim for 7 to 8 hours of restful sleep. 
                  </p>
               </div>
            </div>
            <div class="col-md-3">
               <div class="icon_border_card custome_border border_black">
                  <div class="d-flex align-items-center justify-content-between mb_24">
                     <div class="mr_22"><img src="../assets/images/headphone.svg" alt="img" class="box_icon"></div>
                     <div>
                        <a href="#" class="btn btn_outline">
                           <svg xmlns="http://www.w3.org/2000/svg" id="play_icon" width="31" height="31" viewBox="0 0 31 31">
                              <circle id="bg" cx="15.5" cy="15.5" r="15.5" fill="#ff9500"/>
                              <g id="Triangle" transform="translate(21.478 9.205) rotate(90)" fill="#fff" stroke-linejoin="round" stroke-miterlimit="10">
                                 <path d="M 13.20730304718018 9.704782485961914 L 12.27304363250732 9.704782485961914 L 3.246765118092299e-06 9.704782485961914 L -0.9342567324638367 9.704782485961914 L -0.4160267412662506 8.927433013916016 L 5.720493316650391 -0.2773473262786865 L 6.136523246765137 -0.9013873338699341 L 6.552553176879883 -0.2773473262786865 L 12.68907356262207 8.927433013916016 L 13.20730304718018 9.704782485961914 Z" stroke="none"/>
                                 <path d="M 6.136523246765137 2.86102294921875e-06 L 2.86102294921875e-06 9.204782485961914 L 12.27304363250732 9.204782485961914 L 6.136523246765137 2.86102294921875e-06 M 6.136523246765137 -0.9999971389770508 C 6.470873355865479 -0.9999971389770508 6.783103466033936 -0.8328971862792969 6.968573093414307 -0.5546970367431641 L 13.10509300231934 8.650082588195801 C 13.30966377258301 8.956942558288574 13.32873344421387 9.351482391357422 13.15471363067627 9.676642417907715 C 12.98070335388184 10.00180244445801 12.64184284210205 10.20478248596191 12.27304363250732 10.20478248596191 L 2.86102294921875e-06 10.20478248596191 C -0.3687963485717773 10.20478248596191 -0.7076568603515625 10.00180244445801 -0.8816671371459961 9.676642417907715 C -1.055686950683594 9.351482391357422 -1.036616325378418 8.956942558288574 -0.8320465087890625 8.650082588195801 L 5.304473400115967 -0.5546970367431641 C 5.489943027496338 -0.8328971862792969 5.802173137664795 -0.9999971389770508 6.136523246765137 -0.9999971389770508 Z" stroke="none" fill="#fff"/>
                              </g>
                           </svg>
                           Watch Video Now   
                        </a>
                     </div>
                  </div>
                  <h4 class="font_size_30 text_balticsea mb_14 font_bold">
                     Headphones
                  </h4>
                  <p class="font_size_22 text_balticsea mb_26">
                     Headphones and earbuds are great for privacy and tuning out distractions, but they can take a toll on your ears.
                  </p>
                  <p class="font_size_22 text_balticsea mb_0">
                     Keep your volume between 60  and 85 decibels.  Make sure to limit your use of headphones and earbuds during the day. 
                  </p>
               </div>
            </div>
         </div>
         <div class="row mb_60">
            <div class="col-md-3">
               <div class="icon_border_card custome_border border_black">
                  <div class="mb_24">
                     <img src="../assets/images/alarm.svg" alt="img" class="box_icon">
                  </div>
                  <p class="font_size_22 text_balticsea mb_14">
                     Aim for 7 - 8 hours
                  </p>
                  <p class="font_size_22 text_balticsea mb_0">
                     Sounds crazy, but make sure you schedule enough time for sleep. Instead of cutting back on sleep in order to 
                     tackle the rest of your daily tasks, put sleep at the top of your to-do list and aim for 7 to 8 hours of 
                     restful sleep. 
                  </p>
               </div>
            </div>
            <div class="col-md-9">
               <div class="icon_border_card custome_border border_black">
                  <div class="mb_24">
                     <img src="../assets/images/circle_phone.svg" alt="img" class="box_icon">
                  </div>
                  <p class="font_size_22 text_balticsea mb_16">
                     Plan for distractions
                  </p>
                  <p class="font_size_22 text_balticsea mb_20">
                     When you need to do a heads down stretch of work, the following hacks will help you avoid distractions. And the best way to avoid 
                     distractions is to anticipate them. Here are some ideas.
                  </p>
                  <ul class="pl_0 mb_0">
                     <li>
                        <p>1. Block your calendar - Block out stretches of time for heads down work, which show up on your calendar as busy.</p>
                     </li>
                     <li>
                        <p>2. Put an away message on - Take the pressure off of yourself and put an away message to manage expectations.</p>
                     </li>
                     <li>
                        <p>3. Turn off all alerts - This includes your email, texts, tweets, phones, Slack and other sources of alerts.</p>
                     </li>
                     <li>
                        <p>4. Psych yourself - Get into the mindset that you will be off-line and heads down and stick to it.</p>
                     </li>
                     <li>
                        <p>5. Tune out noise - It’s best to work in a quiet environment or control what you listen to, which can include motivating  
                           music or ambient sounds.  Noise canceling headphones also work wonders to tune out nearby noise and distractions.
                        </p>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <!-- border icon card end -->
      </div>
   </div>
</section>
<!-- card  End-->
<!-- more lab start  -->
<section class="more_lab_tips bg_grey pt_42">
   <div class="container">
      <div class="row justify-content-center ">
         <div class="col-md-12 col-xl-11 px_20">
            <div class="row">
               <div class="col-md-12">
                  <h4 class="heading_4 font_bold mb-0">More setup guidelines</h4>
               </div>
            </div>
            <div class="row">
               <div class="col-sm-12 col-md-6 col-xl-4">
                  <a href="#" class="more_lab_tip_link">
                     <div class="d-flex setup-guide">
                        <div class="flex-shrink-0 media-left">
                           <img src="./../assets/images/setup-1.png" alt="img" class="img-fluid">
                        </div>
                        <div class="flex-grow-1 ml_20 media-right">
                           <h6 class="font_size_22 text_black mb_10">Seating</h6>
                           <p>Ergo has your back (literally)  no matter where you sit. </p>
                        </div>
                     </div>
                  </a>
               </div>
               <div class="col-sm-12 col-md-6 col-xl-4">
                  <a href="#" class="more_lab_tip_link">
                     <div class="d-flex setup-guide">
                        <div class="flex-shrink-0 media-left">
                           <img src="./../assets/images/setup-2.png" alt="img" class="img-fluid">
                        </div>
                        <div class="flex-grow-1 ml_20 media-right">
                           <h6 class="font_size_22 text_black mb_10">Desk</h6>
                           <p>Learn to set yourself up well at any desk you use.  </p>
                        </div>
                     </div>
                  </a>
               </div>
               <div class="col-sm-12 col-md-6 col-xl-4">
                  <a href="#" class="more_lab_tip_link">
                     <div class="d-flex setup-guide">
                        <div class="flex-shrink-0 media-left">
                           <img src="./../assets/images/setup-3.png" alt="img" class="img-fluid">
                        </div>
                        <div class="flex-grow-1 ml_20 media-right">
                           <h6 class="font_size_22 text_black mb_10">Desktop Items</h6>
                           <p>How and what you place on your desk impacts your work style. </p>
                        </div>
                     </div>
                  </a>
               </div>
               <div class="col-sm-12 col-md-6 col-xl-4">
                  <a href="#" class="more_lab_tip_link">
                     <div class="d-flex setup-guide">
                        <div class="flex-shrink-0 media-left">
                           <img src="./../assets/images/setup-4.png" alt="img" class="img-fluid">
                        </div>
                        <div class="flex-grow-1 ml_20 media-right">
                           <h6 class="font_size_22 text_black mb_10">Screens</h6>
                           <p>Wanna have great posture and be productive?  Learn how. </p>
                        </div>
                     </div>
                  </a>
               </div>
               <div class="col-sm-12 col-md-6 col-xl-4">
                  <a href="#" class="more_lab_tip_link">
                     <div class="d-flex setup-guide">
                        <div class="flex-shrink-0 media-left">
                           <img src="./../assets/images/setup-5.png" alt="img" class="img-fluid">
                        </div>
                        <div class="flex-grow-1 ml_20 media-right">
                           <h6 class="font_size_22 text_black mb_10">Accessories</h6>
                           <p>It’s all in the details.  Learn how accessories can help. </p>
                        </div>
                     </div>
                  </a>
               </div>
               <div class="col-sm-12 col-md-6 col-xl-4">
                  <a href="#" class="more_lab_tip_link">
                     <div class="d-flex setup-guide">
                        <div class="flex-shrink-0 media-left">
                           <img src="./../assets/images/setup-6.png" alt="img" class="img-fluid">
                        </div>
                        <div class="flex-grow-1 ml_20 media-right">
                           <h6 class="font_size_22 text_black mb_10">Lighting</h6>
                           <p>Learn how to use light to improve your output.  </p>
                        </div>
                     </div>
                  </a>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<!-- more lab end  -->
<!-- Claim Your Space start -->
<br>
<br>
<br>
<section class="claim_space_sec">
   <div class="container">
      <div class="row">
         <div class="col-md-12 mb_60">
            <h1 class="heading_3 mb_20 mt_20 font_bold">Claim Your Space</h1>
         </div>
         <div class="col-md-12 col-xl-9 col-12">
            <div class="claim_box d-flex">
               <div class="claim_text_image_box">
                  <h4 class="text_primary font_bold font_size_32 mb_0"> Claim Your</h4>
                  <h4 class="text_primary font_bold font_size_32 mb_26"> Space</h4>
                  <img src="../assets/images/home.svg" alt="img">
               </div>
               <div class="claim_text_box">
                  <p class="text_silverchalice mb_44">
                     <strong>Create a separate and distinct work space in your home</strong>, even if its just the corner of a room. This is essential for
                     your concentration, privacy and overall working mindset.
                  </p>
                  <p class="text_dovegray"><strong>Go Flat</strong>. It’s best to set yourself up at a desk, table or other flat, stable surface that
                     can accommodate your computer (laptop), monitor and mouse and any other items you work with. 
                  </p>
               </div>
            </div>
            <div class="claim_box d-flex">
               <div class="claim_text_image_box">
                  <h4 class="text_primary font_bold font_size_32 mb_0"> Know Your</h4>
                  <h4 class="text_primary font_bold font_size_32 mb_26">Patterns</h4>
                  <img src="../assets/images/clock.svg" alt="img">
               </div>
               <div class="claim_text_box">
                  <p class="text_balticsea mb_34">
                     <strong>A few questions you might want to consider:</strong>
                  </p>
                  <ul class="pl_18 mb_0 text_balticsea">
                     <li>
                        <p>What will your new routine look like? </p>
                     </li>
                     <li>
                        <p>At what time of day are you most productive? Early mornings after a healthy 
                           breakfast? Mid-afternoons? Or late at night, once everyone else is sound asleep?
                        </p>
                     </li>
                     <li>
                        <p>If you participate in video calls, consider what your workspace looks like. Will people see an 
                           unmade bed or piles of laundry and dirty dishes? 
                        </p>
                     </li>
                     <li>
                        <p>Do you prefer background music, white noise or complete silence to help you 
                           concentrate? 
                        </p>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<br>
<br>
<br>
<!-- Claim Your Space end -->
<br>
<br>
<br>
<!-- image text start -->
<section class="image_text_sec bg_grey pt_50 pb_50">
   <div class="container">
      <div class="row">
         <div class="col-md-6">
            <div class="image_left_side">
               <img src="../assets/images/image1.png" alt="img">
            </div>
         </div>
         <div class="col-md-6">
            <div class="image_text_right_side">
               <h2 class="mt_10 mb_22">Are you feeling your best? </h2>
               <p class="text_black mb_40">
                  Anyone who works at a lab bench understands the multitude and complexity of tasks involved.
                  Good ergonomics plus good techniques in the lab will boost your energy while helping to stave off unwanted aches and pains.
               </p>
               <p class="text_black mb_20">
                  Here are some guidelines to help you get your ergo on!
               </p>
               <div class="">
                  <a href="#" class="btn btn_outline">
                     <svg xmlns="http://www.w3.org/2000/svg" id="play_icon" width="31" height="31" viewBox="0 0 31 31">
                        <circle id="bg" cx="15.5" cy="15.5" r="15.5" fill="#ff9500"></circle>
                        <g id="Triangle" transform="translate(21.478 9.205) rotate(90)" fill="#fff" stroke-linejoin="round" stroke-miterlimit="10">
                           <path d="M 13.20730304718018 9.704782485961914 L 12.27304363250732 9.704782485961914 L 3.246765118092299e-06 9.704782485961914 L -0.9342567324638367 9.704782485961914 L -0.4160267412662506 8.927433013916016 L 5.720493316650391 -0.2773473262786865 L 6.136523246765137 -0.9013873338699341 L 6.552553176879883 -0.2773473262786865 L 12.68907356262207 8.927433013916016 L 13.20730304718018 9.704782485961914 Z" stroke="none"></path>
                           <path d="M 6.136523246765137 2.86102294921875e-06 L 2.86102294921875e-06 9.204782485961914 L 12.27304363250732 9.204782485961914 L 6.136523246765137 2.86102294921875e-06 M 6.136523246765137 -0.9999971389770508 C 6.470873355865479 -0.9999971389770508 6.783103466033936 -0.8328971862792969 6.968573093414307 -0.5546970367431641 L 13.10509300231934 8.650082588195801 C 13.30966377258301 8.956942558288574 13.32873344421387 9.351482391357422 13.15471363067627 9.676642417907715 C 12.98070335388184 10.00180244445801 12.64184284210205 10.20478248596191 12.27304363250732 10.20478248596191 L 2.86102294921875e-06 10.20478248596191 C -0.3687963485717773 10.20478248596191 -0.7076568603515625 10.00180244445801 -0.8816671371459961 9.676642417907715 C -1.055686950683594 9.351482391357422 -1.036616325378418 8.956942558288574 -0.8320465087890625 8.650082588195801 L 5.304473400115967 -0.5546970367431641 C 5.489943027496338 -0.8328971862792969 5.802173137664795 -0.9999971389770508 6.136523246765137 -0.9999971389770508 Z" stroke="none" fill="#fff"></path>
                        </g>
                     </svg>
                     Watch Video Now   
                  </a>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<br>
<br>
<br>
<section class="image_text_sec text_left_right_image bg_grey pt_50 pb_50">
   <div class="container">
      <div class="row">
         <div class="col-md-6">
            <div class="image_text_right_side">
               <h2 class="mb_22">Set yourself up for success</h2>
               <p class="text_black mb_30">
                  Your desk setup plays a key role in your comfort and productivity.  
                  Whether you sit or stand, you want your desk to be at or near elbow 
                  height and with enough surface space to accommodate all of your desk top items.
               </p>
               <div class="">
                  <a href="#" class="btn btn_outline">
                     <svg xmlns="http://www.w3.org/2000/svg" id="play_icon" width="31" height="31" viewBox="0 0 31 31">
                        <circle id="bg" cx="15.5" cy="15.5" r="15.5" fill="#ff9500"></circle>
                        <g id="Triangle" transform="translate(21.478 9.205) rotate(90)" fill="#fff" stroke-linejoin="round" stroke-miterlimit="10">
                           <path d="M 13.20730304718018 9.704782485961914 L 12.27304363250732 9.704782485961914 L 3.246765118092299e-06 9.704782485961914 L -0.9342567324638367 9.704782485961914 L -0.4160267412662506 8.927433013916016 L 5.720493316650391 -0.2773473262786865 L 6.136523246765137 -0.9013873338699341 L 6.552553176879883 -0.2773473262786865 L 12.68907356262207 8.927433013916016 L 13.20730304718018 9.704782485961914 Z" stroke="none"></path>
                           <path d="M 6.136523246765137 2.86102294921875e-06 L 2.86102294921875e-06 9.204782485961914 L 12.27304363250732 9.204782485961914 L 6.136523246765137 2.86102294921875e-06 M 6.136523246765137 -0.9999971389770508 C 6.470873355865479 -0.9999971389770508 6.783103466033936 -0.8328971862792969 6.968573093414307 -0.5546970367431641 L 13.10509300231934 8.650082588195801 C 13.30966377258301 8.956942558288574 13.32873344421387 9.351482391357422 13.15471363067627 9.676642417907715 C 12.98070335388184 10.00180244445801 12.64184284210205 10.20478248596191 12.27304363250732 10.20478248596191 L 2.86102294921875e-06 10.20478248596191 C -0.3687963485717773 10.20478248596191 -0.7076568603515625 10.00180244445801 -0.8816671371459961 9.676642417907715 C -1.055686950683594 9.351482391357422 -1.036616325378418 8.956942558288574 -0.8320465087890625 8.650082588195801 L 5.304473400115967 -0.5546970367431641 C 5.489943027496338 -0.8328971862792969 5.802173137664795 -0.9999971389770508 6.136523246765137 -0.9999971389770508 Z" stroke="none" fill="#fff"></path>
                        </g>
                     </svg>
                     Watch Video Now   
                  </a>
               </div>
            </div>
         </div>
         <div class="col-md-6">
            <div class="image_left_side">
               <img src="../assets/images/image2.png" alt="img">
            </div>
         </div>
      </div>
   </div>
</section>
<br>
<br>
<br>
<!-- image text end -->
<!-- Set Yourself up for success Starts -->
<section class="set_yourself bg_grey pt_44 pb_50">
   <div class="container">
      <div class="image_left_side">
         <img src="../assets/images/set-yourself.png" alt="img">
      </div>
      <div class="row justify-content-center">
         <div class="col-md-8 col-xl-6">
            <div class="image_text_right_side text-center">
               <h2 class="mt_50 mb_24">Set yourself up for success</h2>
               <p class="text_black mb_24 p_22">
                  Working at home has many positives, but it can pose some challenges.
                  With a few key strategies, you will function at your best. 
               </p>
               <div class="text-center">
                  <a href="#" class="btn btn_outline">
                     <svg xmlns="http://www.w3.org/2000/svg" id="play_icon" width="31" height="31" viewBox="0 0 31 31">
                        <circle id="bg" cx="15.5" cy="15.5" r="15.5" fill="#ff9500"></circle>
                        <g id="Triangle" transform="translate(21.478 9.205) rotate(90)" fill="#fff" stroke-linejoin="round" stroke-miterlimit="10">
                           <path d="M 13.20730304718018 9.704782485961914 L 12.27304363250732 9.704782485961914 L 3.246765118092299e-06 9.704782485961914 L -0.9342567324638367 9.704782485961914 L -0.4160267412662506 8.927433013916016 L 5.720493316650391 -0.2773473262786865 L 6.136523246765137 -0.9013873338699341 L 6.552553176879883 -0.2773473262786865 L 12.68907356262207 8.927433013916016 L 13.20730304718018 9.704782485961914 Z" stroke="none"></path>
                           <path d="M 6.136523246765137 2.86102294921875e-06 L 2.86102294921875e-06 9.204782485961914 L 12.27304363250732 9.204782485961914 L 6.136523246765137 2.86102294921875e-06 M 6.136523246765137 -0.9999971389770508 C 6.470873355865479 -0.9999971389770508 6.783103466033936 -0.8328971862792969 6.968573093414307 -0.5546970367431641 L 13.10509300231934 8.650082588195801 C 13.30966377258301 8.956942558288574 13.32873344421387 9.351482391357422 13.15471363067627 9.676642417907715 C 12.98070335388184 10.00180244445801 12.64184284210205 10.20478248596191 12.27304363250732 10.20478248596191 L 2.86102294921875e-06 10.20478248596191 C -0.3687963485717773 10.20478248596191 -0.7076568603515625 10.00180244445801 -0.8816671371459961 9.676642417907715 C -1.055686950683594 9.351482391357422 -1.036616325378418 8.956942558288574 -0.8320465087890625 8.650082588195801 L 5.304473400115967 -0.5546970367431641 C 5.489943027496338 -0.8328971862792969 5.802173137664795 -0.9999971389770508 6.136523246765137 -0.9999971389770508 Z" stroke="none" fill="#fff"></path>
                        </g>
                     </svg>
                     Watch Video Now   
                  </a>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<!-- Set Yourself up for success Ends -->
<br><br><br>
<!-- pagination Starts -->
<section class="pagination_sec">
   <div class="pagination-nav">
      <div class="container">
         <div class="row justify-content-between">
            <div class="col-md-12">
               <nav aria-label="Page navigation example">
                  <ul class="pagination justify-content-between mb_32">
                     <li class="page-item">
                        <a class=" page-link border-0 text_black" aria-label="Previous">
                        <span aria-hidden="true">
                        <img src="./../assets/images/prev-arrow.png" alt="img" class="mr_10 arrow">
                        Previous
                        </span>
                        </a>
                     </li>
                     <li class="page-item">
                        <a class="page-link border-0 text_black" aria-label="Next">
                        <span aria-hidden="true" class="font_bold">
                        <span class="text_primary">Up Next:</span> Desktop
                         <svg xmlns="http://www.w3.org/2000/svg" width="40" height="29.631" viewBox="0 0 43.575 29.631">
                                          <g id="Left_arrow" data-name="Left arrow" transform="translate(2.75 3.967)">
                                            <path id="Path_30" data-name="Path 30" d="M1755.8,353.578l-10.914,10.889,10.937,10.965" transform="translate(-1744.886 -353.656)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                            <path id="Path_31" data-name="Path 31" d="M1755.8,353.578l-10.914,10.889,10.937,10.965" transform="translate(-1728.886 -353.656)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                          </g>
                                       </svg>                               
                        </span>
                        </a>
                     </li>
                  </ul>
               </nav>
            </div>
         </div>
      </div>
   </div>
</section>
<!-- pagination Ends -->
<!-- Take an ergonomic Starts -->
<div class="heading">
   <div class="container">
      <div class="row">
         <div class="col-md-12">
            <p class="text_black font_size_22 mt_50">Take an ergonomic self-assessment</p>
         </div>
      </div>
   </div>
</div>
<section class="take_an_ergonomic">
   <div class="container">
      <div class="row justify-content-center">
         <div class="col-md-12 col-xl-9 ">
            <div class="row">
               <div class="col-md-12 col-lg-6 pe-lg-0">
                  <div class="bg_orange left-section pt_36 pb_36 pl_78 pr_78 text-center">
                     <h4 class="heading_4 font_bold mb_10 text-white mx-auto">Take an ergonomic
                        self-assessment
                     </h4>
                     <p class="text-white p_25 mx-auto">Take self-assessment today and see how ergo can help you feel great, enjoy more energy and supercharge your productivity. </p>
                     <div class="mt_14">
                        <a href="#" class="btn btn_secondary font_size_20 text-capitalize">Take a Self-Assessment</a>
                     </div>
                  </div>
               </div>
               <div class="col-md-12 col-lg-6 ps-lg-0">
                  <div class="right-section ">
                     <img src="./../assets/images/ergonomic-image.png" alt="Image">
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<!-- Take an ergonomic Ends -->
<!-- Take an ergonomicparttwo Starts -->
<div class="heading mt_50 mb_50">
   <div class="container">
      <div class="row">
         <div class="col-md-12">
            <p class="text_black font_size_22 mt_50">Take an ergonomic self-assessment</p>
         </div>
      </div>
   </div>
</div>
<section class="take_an_ergonomic_parttwo mb_50">
   <div class="container">
      <div class="row justify-content-center">
         <div class="col-md-12 col-xl-11">
            <div class="ergonomic-image">
               <div class="row">
                  <div class="col-md-12 col-lg-7 col-xl-6">
                     <div class="left-section pt_26 pb_26 pl_60 pr_60">
                        <h6 class="sub_heading_1 font_bold mb_22 text-white">Take an ergonomic self-assessment</h6>
                        <p class="text-white p_20 mb_54">Take self-assessment today and see how ergo can help you feel great, enjoy more energy and supercharge your productivity. </p>
                        <div class="mt_14">
                           <a href="#" class="btn btn_secondary text-capitalize font_size_16">Take a Self-Assessment</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<!-- Take an ergonomic Ends -->
<!-- Want some more stretches start -->
<div class="heading mt_50 mb_50">
   <div class="container">
      <div class="row">
         <div class="col-md-12">
            <p class="text_black font_size_22 mt_50">Want some more stretches?</p>
         </div>
      </div>
   </div>
</div>
<section class="want_more_stretches mb_50 ">
   <div class="container">
      <div class="row justify-content-center">
         <div class="col-md-12 col-xl-11">
            <div class="bg_primary">
               <div class="row">
                  <div class="col-md-12 col-lg-6 align-self-center">
                     <div class="left-section pt_42 pb_54 pl_50 mt_6">
                        <h6 class="sub_heading_1  mb_0 text-white ">Want some more stretches?
                        </h6>
                        <p class="text-white font_size_24 fst-italic mb_0">Check out our full video library </p>
                     </div>
                  </div>
                  <div class="col-md-12 col-lg-6 align-self-center text-lg-end">
                     <div class="right-section pt_26 pb_26 pl_60 pr_40">
                        <div class="mt_14">
                           <a href="#" class="btn btn_secondary text-capitalize font_size_16">Video Library</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
<!-- Want some more stretches Ends -->
<!-- Breadcrumb start -->
<section class="bradcrumb_sec">
   <div class="container-fluid custome_container">
      <div class="row">
         <div class="col-md-12">
            <div class="bread_crumb_box mb_22">
               <h2 class="heading_1 font_bold mb_23">Seating</h2>
               <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                     <li class="breadcrumb-item"><router-link class="navbar-brand m-0 p-0" :to="{ name: 'user_home' }">
         Home
        </router-link></li>
                     <li class="breadcrumb-item"><a href="#">Guidelines</a> </li>
                     <li class="breadcrumb-item active" aria-current="page">Seating</li>
                  </ol>
               </nav>
            </div>
         </div>
      </div>
   </div>
</section>
<!-- Breadcrumb end -->
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import store from "@/store/index";

export default {
  name: "style_guide",
  mounted() {
    store.dispatch("Home/getHomeData").then(() => {});
  },
};
</script>